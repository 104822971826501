<template>
  <div>
    <h3>Adresse</h3>
    Grand'Rue 29<br>
    2108 Couvet

    <div>
      <div class="phones-numbers">
        <h3>Tel</h3>
        <span>Sofia: +41 78 765 78 96</span>
        <span>Victor: +41 78 664 98 50</span>
      </div>
    </div>
    <div>
      <h3>E-mail</h3>
      <a href="mailto:contact@svphysio.ch" class="email">
        <span>contact@svphysio.ch</span>
      </a>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Address'
}
</script>

<style scoped>

.phones-numbers {
  display: flex;
  flex-direction: column;
}

h3 {
  margin-bottom: 5px;
}

.email {
  color: inherit;
}

</style>
