<template>
<div>
  <h2>Nos prestations</h2>
  <div>
    Nos traitements, à base de thérapie manuelle et d’exercices thérapeutiques, sont complétés par des techniques
    de pointe en ce qui concerne le domaine de l’adulte (techniques régénératives, invasives sous contrôle
    échographique).
  </div>
</div>
</template>

<script>
export default {
  name: 'Service'
}
</script>

<style scoped>

</style>
