<template>
<div class="office">
  <h2>Le cabinet</h2>
  <div>
    Nous vous accueillons dans notre cabinet à Couvet dès janvier 2022. Grâce aux différentes formations effectuées,
    la qualité de la prise en charge est primordiale pour optimiser nos traitements et cibler au mieux vos besoins,
    ainsi que ceux de vos enfants, quel que soit l’âge !
  </div>
</div>
</template>

<script>
export default {
  name: 'Office'
}
</script>

<style scoped>
.office{
  padding: 30px 0;
}
</style>
