<template>
  <div id="footer">
    <Address class="address"/>
    <Schedule class="schedule"/>
    <img alt="Physioswiss logo" src="@/assets/physioswiss.svg" class="physioswiss-logo">
  </div>
</template>

<script>
import Address from '@/components/home/footer/Address'
import Schedule from '@/components/home/footer/Schedule'

export default {
  name: 'Footer',
  components: {
    Schedule,
    Address
  }
}
</script>

<style scoped>
#footer {
  display: grid;
  column-gap: 10px;
  grid-template-areas: "address schedule logo";
  /*justify-content: space-between;*/
  width: 100%;
  margin: 30px 0;
}

.address {
  grid-area: address;
}

.schedule {
  grid-area: schedule;
}

.physioswiss-logo {
  height: 70px;
  margin-top: 40px;
  grid-area: logo;
}

@media (max-width: 961px) {
  #footer {
    grid-template-areas:
      "address schedule"
      "logo logo";
  }
}

@media (max-width: 641px) {
  #footer {
    grid-template-areas:
      "address"
      "schedule"
      "logo";
  }
}

</style>
