<template>
  <transition
    enter-active-class="animate__animated animate__fadeInDown animate__faster"
    leave-active-class="animate__animated animate__fadeOutDown animate__faster"
  >
    <div id="menu" v-if="isOpen">
      <img alt="Logo close icon" src="@/assets/close.svg" class="icon-close" @click="onClose">
      <div class="content">
        <router-link
          v-for="menuLink in menuLinks"
          :to="menuLink.link"
          :key="menuLink.link"
          @click="onClose"
        >
          {{ menuLink.title }}
        </router-link>
      </div>
    </div>
  </transition>
</template>

<script>
import 'animate.css'

export default {
  name: 'Menu',
  props: {
    isOpen: { type: Boolean }
  },
  data: () => ({
    menuLinks: [{
      link: '/',
      title: 'Accueil'
    }, {
      link: '/equipe',
      title: 'Équipe'
    },
    {
      link: '/soins',
      title: 'Soins et techniques proposées'
    }, {
      link: '/contact',
      title: 'Contact'
    }]
  }),
  methods: {
    onClose: function () {
      this.$emit('close-menu')
    }
  }
}
</script>

<style scoped>
#menu {
  position: fixed;
  z-index: 100;
  background-color: var(--secondary-color);
  width: 100vw;
  height: 100vh;
  opacity: 0.96;
  overflow-y: scroll;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 10vh 2vh;
}

a {
  color: var(--primary-color);
  font-size: xx-large;
  text-align: center;
  background: linear-gradient(
    to bottom, var(--primary-color) 0%,
    var(--primary-color) 100%
  ) repeat-x 0 100%;
  background-size: 2px 2px;
  /*color: #000;*/
  text-decoration: none;
  transition: all .2s;
  padding: 0 15px;
}

a + a {
  margin-top: 50px;
}

a:hover {
  background-size: 4px 50px;
  color: var(--secondary-color);
}

.icon-close {
  height: 50px;
  cursor: pointer;
  padding: 50px 50px;
  position: fixed;
  right: 0;
}

@media (max-width: 961px) {
  a {
    font-size: xx-large;
  }
}

@media (max-width: 641px) {
  a {
    font-size: x-large;
    padding: unset;
  }

  .icon-close {
    height: 35px;
    padding: 10px 10px;
  }
}
</style>
