
import { defineComponent } from 'vue'
import Block, { BLOCK_SVG } from '@/components/Block.vue'
import Infos from '@/components/Infos.vue'
import Service from '@/components/Service.vue'
import Office from '@/components/Office.vue'
import MatrixText from '@/components/MatrixText.vue'
import News from '@/components/News/News.vue'

export default defineComponent({
  name: 'Home',
  components: {
    News,
    MatrixText,
    // Footer,
    Office,
    Service,
    Infos,
    Block
  },
  data: () => ({
    BLOCK_SVG
  })
})
