<template>
<div>
  <h3>Horaires</h3>
  lundi - vendredi: 07h30-17h30
</div>
</template>

<script>
export default {
  name: 'Schedule'
}
</script>

<style scoped>

</style>
