
import { defineComponent } from 'vue'

export enum BLOCK_SVG {
  'light' = 'light',
  'dark' = 'dark',
  'none' = 'none'
}

const __default__ = defineComponent({
  name: 'Block',
  props: {
    textColor: String,
    backgroundColor: String
  }
})

import { useCssVars as _useCssVars } from 'vue'
const __injectCSSVars__ = () => {
_useCssVars(_ctx => ({
  "d890d4c6": (_ctx.textColor),
  "454cf4fc": (_ctx.backgroundColor)
}))}
const __setup__ = __default__.setup
__default__.setup = __setup__
  ? (props, ctx) => { __injectCSSVars__();return __setup__(props, ctx) }
  : __injectCSSVars__

export default __default__