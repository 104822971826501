<template>
  <div id="header">
    <!--  <img alt="SVPhysio logo" src="../assets/logo.png" id="header-logo">-->
    <div id="header-logo">
      <router-link to="/">
        <img alt="SVPhysio logo" src="../assets/logo.png" class="header-logo-icon">
      </router-link>
    </div>

    <!--  <a href="/">-->
    <!--    <img alt="SVPhysio logo" src="../assets/logo.png" id="header-logo" height="100%">-->
    <!--  </a>-->
    <div id="slogan"> Un cadre chaleureux et dynamique, on se réjouit de vous voir !</div>
    <div id="menu-icon-wrapper">
      <img alt="menu" src="../assets/menu.png" id="menu-icon" @click="onMenuClick">
    </div>
    <Menu :is-open="isMenuOpen" @close-menu="onMenuClose"/>
  </div>
</template>

<script>
import Menu from '@/components/Menu'

export default {
  name: 'Header',
  components: { Menu },
  data: () => (
    {
      isMenuOpen: false
    }
  ),
  methods: {
    onMenuClick: function () {
      this.isMenuOpen = true
    },
    onMenuClose: function () {
      this.isMenuOpen = false
    }
  }
}
</script>

<style scoped>
#header {
  display: flex;
  background-color: var(--primary-color);
  width: 100%;
  height: 70px;
  padding: 12px 0 0;
}

#header-logo {
  height: 100%;
}

#menu-icon-wrapper {
  display: flex;
  align-items: center;
  margin-right: 10px;
  cursor: pointer;
}

#menu-icon {
  height: 50px;
  padding-bottom: 12px;
}

#slogan {
  height: 100%;
  display: flex;
  flex: 1;
  color: var(--secondary-color-light);
  align-items: center;
  margin-left: 30px;
}

.header-logo-icon {
  height: 100%;
}

@media (max-width: 641px) {
  #slogan {
    visibility: hidden;
  }
}
</style>
