<template>
  <div ref="textRef"/>
</template>

<script>
import { onBeforeUnmount, onMounted, ref } from 'vue'

export default {
  name: 'MatrixText',
  props: {
    text: {
      type: String,
      default: ''
    }
  },
  setup (props) {
    const textRef = ref(null)
    const timeouts = []

    const showOriginalContent = (index) => {
      const { text } = props

      timeouts.push(
        setTimeout(() => {
          const el = document.getElementById(`char-${index}`)
          el.style.display = ''
        }, index * 50)
      )
    }

    onMounted(() => {
      const { text } = props
      const allChars = text.split('').map((value, index) => {
        const character = document.createElement('span')
        character.innerHTML = value
        character.id = (`char-${index}`)
        character.style.display = 'none'
        character.style.animation = 'fadeIn 0.5s'
        textRef.value.appendChild(character)
      })

      timeouts.push(setTimeout(() => {
        for (let i = 0; i < text.length; i++) {
          showOriginalContent(i)
        }
      }, 1000))
    })

    onBeforeUnmount(() => {
      timeouts.forEach(timeout => clearTimeout(timeout))
    })

    return {
      textRef
    }
  }
}
</script>

<style>
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

</style>
