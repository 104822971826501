<template>
  <div id="infos">
    <div class="content">
      <h2>Infos</h2>
      <p>
        Votre rééducation sera prise en charge par votre assurance maladie
        de base. Les soins seront remboursés par la LAMal ou par
        votre assurance accident.
      </p>
      <p>
        Traitement sans ordonnance (120.-/heure)
      </p>
      <div class="warning">
        <img alt="attention" src="@/assets/icons/warning.png" class="warning-icon">
        <p>
          Attention: le traitement doit être commencé
          dans les 5 semaines après l’obtention de la prescription
        </p>
      </div>
    </div>
    <img alt="bear pediatry" src="@/assets/bear.png" class="bear desktop-only">
  </div>
</template>

<script>
export default {
  name: 'Infos'
}
</script>

<style scoped>
#infos {
  display: flex;
  /*padding: 0 24px;*/
}

.content {
  text-align: justify;
  display: flex;
  flex-direction: column;
}

.bear {
  height: 300px;
}

.content + .bear {
  margin-left: 100px;
}

.warning {
  display: flex;
  font-size: smaller;
  align-items: center;
  max-width: 600px;
}

.warning-icon {
  height: 40px;
  margin-right: 20px;
}

/*@media (max-width:641px) {*/

/*}*/
</style>
